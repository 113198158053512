import './set-public-path';
import { h, createApp } from 'vue';
import singleSpaVue from 'single-spa-vue';

import { createRouter, createWebHistory } from 'vue-router';

import App from './App.vue';
import store from './store/store';
import { i18n } from './plugins/i18n';

const vueLifecycles = singleSpaVue({
  createApp,
  appOptions: {
    render() {
      return h(App, {});
    }
  },

  async handleInstance(app, props) {
    const routesList = props.routesList || [];
    const router = createRouter({
      history: createWebHistory(process.env.BASE_URL),
      routes: routesList.map(route => ({
        ...route,
        component: () => {
          const viewsList = ['Pest'];
          if (viewsList.map(view => view.toLowerCase()).includes(route.component.toLowerCase())) {
            return import(
              `@/views/${route.component[0].toUpperCase() + route.component.slice(1)}.vue`
            );
          } else {
            return import(`@/views/${viewsList[0]}.vue`);
          }
        }
      }))
    });
    app.use(store);
    app.use(router);
    app.use(await i18n());
  }
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
